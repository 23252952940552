import { collection, getFirestore, query ,where, getDocs  } from 'firebase/firestore'

const alertCollection = collection(getFirestore(), 'alerts_bu')

/**
 * Find an alert by bu {@link buId}.
 * @param {number} buId e.g. 1
 * @return {Promise<object>}
 */
export async function findAlertByBu (buId) {

    const q = query(alertCollection, where('buId', '==', buId))
    const querySnapshot = await getDocs(q)

    if (!querySnapshot.empty) {
      const alert = querySnapshot.docs[0].data()
      return alert
    }
}

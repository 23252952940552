export default {
  "flash-report_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flash Report"])},
  "flash-report_application_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "flash-report_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeo"])},
  "flash-report_application_noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])},
  "flash-report_application_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
  "flash-report_navigation_myBu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
  "flash-report_navigation_myRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
  "flash-report_navigation_myStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon magasin"])},
  "flash-report_navigation_store_prepend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "flash-report_navigation_region_prepend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
  "flash-report_navigation_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "flash-report_nagivation_add_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un magasin"])},
  "flash-report_navigation_add_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une région"])},
  "flash-report_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "flash-report_dashboard_store": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Magasin de ", _interpolate(_named("value"))])},
  "flash-report_dashboard_region": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Région ", _interpolate(_named("value"))])},
  "flash-report_dashboard_bu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value"))])},
  "flash-report_dashboard_myBu": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myBu", undefined, _type)])},
  "flash-report_dashboard_myRegion": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myRegion", undefined, _type)])},
  "flash-report_dashboard_myStore": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myStore", undefined, _type)])},
  "flash-report_dashboard_departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rayons"])},
  "flash-report_dashboard_departments_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rayons de ", _interpolate(_named("value"))])},
  "flash-report_dashboard_myDepartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes rayons"])},
  "flash-report_dashboard_header_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_title", undefined, _type)])},
  "flash-report_dashboard_comparisonDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("timeFromNow")), ", comparée au <strong>", _interpolate(_named("date")), "</strong>"])},
  "flash-report_dashboard_noReport_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_noData", undefined, _type)])},
  "flash-report_dashboard_noReport_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pour l'instant aucune donnée."])},
  "flash-report_dashboard_noReport_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de magasin"])},
  "flash-report_dashboard_turnoverSummary_currentTurnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA"])},
  "flash-report_dashboard_turnoverSummary_previousTurnover": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CA ", _interpolate(_named("year"))])},
  "flash-report_dashboard_orderSummary_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En-commande"])},
  "flash-report_dashboard_orderSummary_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours"])},
  "flash-report_dashboard_orderSummary_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA issu de commandes"])},
  "flash-report_dashboard_record_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Record !"])},
  "flash-report_authentication_callback_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification"])},
  "flash-report_authentication_callback_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "flash-report_authentication_callback_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas réussi à vous authentifier."])},
  "flash-report_authentication_callback_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_retry", undefined, _type)])},
  "flash-report_authentication_authenticated-user_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ", _interpolate(_named("value")), ","])},
  "flash-report_region-select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une région"])},
  "flash-report_region-select_search_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chercher parmi l'une de nos ", _interpolate(_named("value")), " régions"])},
  "flash-report_region-select_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Agglomération Nord, Grand Paris"])},
  "flash-report_region-select_noRegion_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_noData", undefined, _type)])},
  "flash-report_region-select_noRegion_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semblerait que pour votre recherche, nous ne trouvions aucune région."])},
  "flash-report_region-select_myRegion": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myRegion", undefined, _type)])},
  "flash-report_store-select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un magasin"])},
  "flash-report_store-select_noStore_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_noData", undefined, _type)])},
  "flash-report_store-select_noStore_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semblerait que pour votre recherche, nous ne trouvions aucun magasin."])},
  "flash-report_store-select_inError_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "flash-report_store-select_inError_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons un soucis pour récupérer la liste des magasins."])},
  "flash-report_store-select_inError_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_retry", undefined, _type)])},
  "flash-report_store-select_search_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chercher parmi l'un de nos ", _interpolate(_named("value")), " magasins"])},
  "flash-report_store-select_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Merlimont, Boulogne"])},
  "flash-report_store-select_myStore": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myStore", undefined, _type)])},
  "flash-report_bu-select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Unit"])},
  "flash-report_search-input_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours ..."])},
  "flash-report_feedback-link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner un feedback"])},
  "flash-report_feedback-link_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur Tangram"])},
  "flash-report_bug-link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarer un bug"])},
  "flash-report_bug-link_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur Service Now"])},
  "flash-report_blur_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu sembles parti !"])},
  "flash-report_blur_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour protéger les informations qui s'y affichent des regards indiscrets, l'écran est flouté."])},
  "flash-report_blur_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre"])},
  "flash-report_ranking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("value")), "<sup>er</sup>"]), _normalize([_interpolate(_named("value")), "<sup>e</sup>"])])},
  "flash-report_light-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair"])},
  "flash-report_dark-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombre"])},
  "flash-report_auto-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
  "flash-report_department_building-materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériaux de construction"])},
  "flash-report_department_carpentry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menuiserie"])},
  "flash-report_department_electricity-plumbing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Électricité-plomberie"])},
  "flash-report_department_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outillage"])},
  "flash-report_department_kitchen-storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangement cuisine"])},
  "flash-report_department_floor-wall-tiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sol et carrelage mural"])},
  "flash-report_department_sanitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanitaire"])},
  "flash-report_department_comfort-renewable-energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confort & énergie renouvelable"])},
  "flash-report_department_garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jardin"])},
  "flash-report_department_hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quincaillerie"])},
  "flash-report_department_paint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peinture"])},
  "flash-report_department_decoration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décoration"])},
  "flash-report_department_lighting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éclairage"])},
  "flash-report_notification_title_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalie détectée"])},
  "flash-report_notification_title_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dysfonctionnement en cours"])},
  "flash-report_notification_title_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interruption du service"])},
  "flash-report_notification_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
}
export default {
  "flash-report_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flash Report"])},
  "flash-report_application_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "flash-report_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeo"])},
  "flash-report_application_noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun Dato"])},
  "flash-report_application_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova"])},
  "flash-report_navigation_myBu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazionale"])},
  "flash-report_navigation_myRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regionale"])},
  "flash-report_navigation_myStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio Negozio"])},
  "flash-report_navigation_store_prepend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
  "flash-report_navigation_region_prepend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
  "flash-report_navigation_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
  "flash-report_navigation_add_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un negozio"])},
  "flash-report_navigation_add_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una regione"])},
  "flash-report_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "flash-report_dashboard_store": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Negozio di ", _interpolate(_named("value"))])},
  "flash-report_dashboard_region": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Regione ", _interpolate(_named("value"))])},
  "flash-report_dashboard_bu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value"))])},
  "flash-report_dashboard_myBu": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myBu", undefined, _type)])},
  "flash-report_dashboard_myRegion": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myRegion", undefined, _type)])},
  "flash-report_dashboard_myStore": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myStore", undefined, _type)])},
  "flash-report_dashboard_departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparti"])},
  "flash-report_dashboard_departments_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reparto ", _interpolate(_named("value"))])},
  "flash-report_dashboard_myDepartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei reparti"])},
  "flash-report_dashboard_header_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_title", undefined, _type)])},
  "flash-report_dashboard_comparisonDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("timeFromNow")), ", confrontato con <strong>", _interpolate(_named("date")), "</strong>"])},
  "flash-report_dashboard_noReport_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_noData", undefined, _type)])},
  "flash-report_dashboard_noReport_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non abbiamo dati."])},
  "flash-report_dashboard_noReport_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un altro negozio"])},
  "flash-report_dashboard_turnoverSummary_currentTurnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cifra Prodotto"])},
  "flash-report_dashboard_turnoverSummary_previousTurnover": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cifra Prodotto ", _interpolate(_named("year"))])},
  "flash-report_dashboard_orderSummary_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini Validati"])},
  "flash-report_dashboard_orderSummary_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resi"])},
  "flash-report_dashboard_orderSummary_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CA dagli ordini"])},
  "flash-report_dashboard_record_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Record"])},
  "flash-report_authentication_callback_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione"])},
  "flash-report_authentication_callback_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "flash-report_authentication_callback_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione non riuscita."])},
  "flash-report_authentication_callback_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_retry", undefined, _type)])},
  "flash-report_authentication_authenticated-user_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Buongiorno ", _interpolate(_named("value")), ","])},
  "flash-report_store-select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un negozio"])},
  "flash-report_store-select_noStore_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun negozio trovato"])},
  "flash-report_store-select_noStore_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che per la tua ricerca non sia presente alcun negozio."])},
  "flash-report_store-select_inError_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
  "flash-report_store-select_inError_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'è un problema per recuperare l'elenco dei negozi. Per favore riprova più tardi."])},
  "flash-report_store-select_inError_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_retry", undefined, _type)])},
  "flash-report_store-select_search_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cerca tra i nostri ", _interpolate(_named("value")), " negozi"])},
  "flash-report_store-select_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es."])},
  "flash-report_store-select_myStore": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myStore", undefined, _type)])},
  "flash-report_bu-select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Unit"])},
  "flash-report_search-input_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento in corso ..."])},
  "flash-report_feedback-link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornire un feedback"])},
  "flash-report_feedback-link_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sul Tangram"])},
  "flash-report_bug-link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnalare un bug"])},
  "flash-report_bug-link_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sul Service Now"])},
  "flash-report_blur_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembri andato!"])},
  "flash-report_blur_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per proteggere le informazioni visualizzate su di esso da occhi indiscreti, lo schermo è sfocato."])},
  "flash-report_blur_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprendere"])},
  "flash-report_ranking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "<sup>o</sup>"])},
  "flash-report_light-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiaro"])},
  "flash-report_dark-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scuro"])},
  "flash-report_auto-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
  "flash-report_notification_title_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomalia rilevata"])},
  "flash-report_notification_title_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malfunzionamento in corso"])},
  "flash-report_notification_title_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interruzione del servizio"])},
  "flash-report_notification_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])}
}
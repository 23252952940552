export default {
  "flash-report_application_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flash Report"])},
  "flash-report_application_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "flash-report_application_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adeo"])},
  "flash-report_application_noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
  "flash-report_application_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
  "flash-report_navigation_myBu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National"])},
  "flash-report_navigation_myRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional"])},
  "flash-report_navigation_myStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My store"])},
  "flash-report_navigation_store_prepend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
  "flash-report_navigation_region_prepend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
  "flash-report_navigation_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "flash-report_navigation_add_store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A store"])},
  "flash-report_navigation_add_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A region"])},
  "flash-report_dashboard_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "flash-report_dashboard_store": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store of ", _interpolate(_named("value"))])},
  "flash-report_dashboard_region": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Region ", _interpolate(_named("value"))])},
  "flash-report_dashboard_bu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value"))])},
  "flash-report_dashboard_myBu": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myBu", undefined, _type)])},
  "flash-report_dashboard_myRegion": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myRegion", undefined, _type)])},
  "flash-report_dashboard_myStore": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myStore", undefined, _type)])},
  "flash-report_dashboard_departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])},
  "flash-report_dashboard_departments_of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Departments of ", _interpolate(_named("value"))])},
  "flash-report_dashboard_myDepartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My departments"])},
  "flash-report_dashboard_header_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_title", undefined, _type)])},
  "flash-report_dashboard_comparisonDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("timeFromNow")), ", compared to <strong>", _interpolate(_named("date")), "</strong>"])},
  "flash-report_dashboard_noReport_title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_noData", undefined, _type)])},
  "flash-report_dashboard_noReport_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have no data as for now."])},
  "flash-report_dashboard_noReport_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an other store"])},
  "flash-report_dashboard_turnoverSummary_currentTurnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnover"])},
  "flash-report_dashboard_turnoverSummary_previousTurnover": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Turnover ", _interpolate(_named("year"))])},
  "flash-report_dashboard_orderSummary_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On order"])},
  "flash-report_dashboard_orderSummary_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned goods"])},
  "flash-report_dashboard_orderSummary_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed orders"])},
  "flash-report_dashboard_record_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New record"])},
  "flash-report_authentication_callback_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
  "flash-report_authentication_callback_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "flash-report_authentication_callback_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not able to authenticate you."])},
  "flash-report_authentication_callback_error_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_retry", undefined, _type)])},
  "flash-report_authentication_authenticated-user_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi ", _interpolate(_named("value")), ","])},
  "flash-report_store-select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a store"])},
  "flash-report_store-select_noStore_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No store found"])},
  "flash-report_store-select_noStore_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that for your search, we couldn't find any stores."])},
  "flash-report_store-select_inError_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "flash-report_store-select_inError_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a problem to retrieve the list of stores. Please try again later."])},
  "flash-report_store-select_inError_button": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_application_retry", undefined, _type)])},
  "flash-report_store-select_search_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search over ", _interpolate(_named("value")), " stores"])},
  "flash-report_store-select_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Merlimont, Boulogne"])},
  "flash-report_store-select_myStore": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("flash-report_navigation_myStore", undefined, _type)])},
  "flash-report_bu-select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Unit"])},
  "flash-report_search-input_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading ..."])},
  "flash-report_feedback-link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a feedback"])},
  "flash-report_feedback-link_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Tangram"])},
  "flash-report_bug-link_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declare a bug"])},
  "flash-report_bug-link_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Service Now"])},
  "flash-report_blur_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You seem gone!"])},
  "flash-report_blur_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To protect the information displayed on it from prying eyes, the screen is blurred."])},
  "flash-report_blur_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To resume"])},
  "flash-report_ranking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), "<sup>th</sup>"])},
  "flash-report_light-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "flash-report_dark-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
  "flash-report_auto-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
  "flash-report_notification_title_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomaly detected"])},
  "flash-report_notification_title_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malfunction in progress"])},
  "flash-report_notification_title_critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service interruption"])},
  "flash-report_notification_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
}
<template>
<v-btn v-if="showButton" @click="dialog = true" class="ml-4" icon>
  <FrIcon v-if="showAlertButton" modelValue="alert" color="red" class="blinking-icon" />
  <FrIcon v-if="showInfoButton" modelValue="info" class="blinking-icon" />
</v-btn>

<v-dialog v-model="dialog" max-width="500">
<v-card>
  <v-card-title class="headline"> {{ title }}</v-card-title>
  <v-card-text>
    {{ message }}
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
</template>

<script setup>

import { onMounted, ref } from 'vue'
import FrIcon from '@/components/FrIcon'
import { findAlertByBu } from '../services/alert';
import { findUserProfile } from '@/user/services/repositories/userProfileRepository'
const { t } = useI18n()
import { useI18n } from 'vue-i18n'
const dialog = ref(false)
const showAlertButton = ref(false)
const showInfoButton = ref(false)
const showButton = ref(false)
const message = ref(undefined)
const title = ref()

onMounted(async () => {
  const userProfile = await findUserProfile()
  const alertBu = await findAlertByBu(userProfile?.selectedBuId)
  if (alertBu.hasAlert != Boolean(false)){
    showButton.value = true
    if(alertBu.statusAlert != 'info'){
      showAlertButton.value = Boolean(true)
    }else{
      showInfoButton.value = Boolean(true)
    }
    message.value = alertBu.messageAlert
    title.value = t('flash-report_notification_title_'+alertBu.statusAlert)
  }
})

</script>

<style scoped>
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-icon {
  animation: blink 1s infinite;
}
</style>
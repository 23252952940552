<template>
  <v-navigation-drawer app
                       class="app-menu"
                       id="app-menu"
                       :modelValue="isOpened"
                       @update:modelValue="toggleMenu">
    <template #prepend>
      <div class="app-menu-header pt-5 pl-5 pr-5">
        <FrTitle>
          <AuthenticationAuthenticatedUser />
        </FrTitle>
        <v-spacer />
        <v-btn class="app-menu-button"
               flat
               icon
               @click="toggleMenu(false)">
          <FrIcon modelValue="close" />
        </v-btn>
      </div>
    </template>
    <v-list nav>
      <v-list-item :href="feedbackHref"
                   lines="two"
                   target="_blank">
        <template #prepend>
          <v-avatar>
            <FrIcon modelValue="star" />
          </v-avatar>
        </template>
        <template #title>{{ t('flash-report_feedback-link_label') }}</template>
        <template #subtitle>{{ t('flash-report_feedback-link_provider') }}</template>
      </v-list-item>
      <v-list-item :href="bugHref"
                   lines="two"
                   target="_blank">
        <template #prepend>
          <v-avatar>
            <FrIcon modelValue="bug" />
          </v-avatar>
        </template>
        <template #title>{{ t('flash-report_bug-link_label') }}</template>
        <template #subtitle>{{ t('flash-report_bug-link_provider') }}</template>
      </v-list-item>
    </v-list>
    <template #append>
      <div class="app-menu-footer pa-5">
        <img alt="Company logo of Adeo"
             class="app-menu-footer-logo"
             :src="logo" />
             <v-spacer/>
             <div>
                <FrThemeSelect :modelValue="theme" @update:modelValue="persistTheme" @update:theme="updateTheme" />
             </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import adeoLogo from '@/assets/logo_adeo.png'

import FrIcon from '@/components/FrIcon'
import FrTitle from '@/components/FrTitle'
import FrThemeSelect from '@/components/FrThemeSelect'

import AuthenticationAuthenticatedUser from '@/authentication/components/AuthenticationAuthenticatedUser'

import { createOrUpdateUserProfile, findUserProfile } from '@/user/services/repositories/userProfileRepository'

const store = useStore()
const { t } = useI18n()

const logo = ref(adeoLogo)

const isOpened = computed(() => store.state.app.isMenuOpened)
const feedbackHref = computed(() => `https://tangram.adeo.com/products/${process.env.VUE_APP_TANGRAM_ID}/feedback/standalone?showFeatureSuggestionsLink=true`)

const bugHref = ref(process.env.VUE_APP_SERVICE_NOW_URL)
const theme = ref(undefined)

function toggleMenu (isOpened) {
  if (!isOpened) {
    store.dispatch('app/closeMenu')
  }
}

function persistTheme(modelValue) {
  theme.value = modelValue
  createOrUpdateUserProfile({ theme: theme.value })
}

function updateTheme(theme) { 
  store.dispatch('app/changeTheme', theme)
}

onMounted(async ()=>{
  const userProfile = await findUserProfile()
  theme.value = userProfile?.theme
  if (userProfile?.buCode == "FR-LM" || userProfile?.buCode == "GO-LM"){
    bugHref.value = process.env.VUE_APP_SERVICE_NOW_URL_LMFR
  }else {
    bugHref.value = process.env.VUE_APP_SERVICE_NOW_URL
  }
})
</script>

<style scoped lang="sass">
.app-menu
  &-header
    display: flex
    align-items: flex-start
    justify-content: space-between

  &-footer
    display: flex
    align-items: center

    &-logo
      width: 50px
      height: 100%
</style>

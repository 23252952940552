<template>
  <div class="fr-application-logo">
    <h6 class="fr-application-logo-primary text-h6">
      {{ t("flash-report_application_title") }}
    </h6>
    <v-slide-x-transition>
      <div
        class="fr-application-logo-secondary ml-1 text-caption text-grey-darken-1"
        v-if="isSecondaryDisplayed"
      >
        <span class="mt-1">{{ t("flash-report_application_by") }}</span>
        <img
          alt="Company logo"
          class="ml-1"
          :src="logo"
          :width="logoWidth"
          :height="logoHeight"
        />
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script setup>
import { onMounted, ref,computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();
const isSecondaryDisplayed = ref(false);
const logo = ref('');
const logoWidth = ref(0);
const logoHeight = ref(0);
const store = useStore();

const theme = computed(() => store.state.app.theme)


onMounted(() => {
  setTimeout(() => {
    isSecondaryDisplayed.value = true;
    logo.value = theme.value === 'darkLm' 
        ? require('@/assets/logo_lm_darkmode.png')
        : require('@/assets/logo_lm.png');
        logoWidth.value = "90";
        logoHeight.value = "60";
  }, 1000);

// Watcher pour détecter les changements de thème
store.watch(() => store.state.app.theme, () => {
  logo.value = theme.value === 'darkLm' 
        ? require('@/assets/logo_lm_darkmode.png')
        : require('@/assets/logo_lm.png');
        logoWidth.value = "90";
        logoHeight.value = "60";
});
})
</script>

<style scoped lang="sass">
.fr-application-logo
  display: flex
  align-items: center

  &-secondary
    display: flex
    align-items: center
</style>
